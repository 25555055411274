import { ChangeEvent, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    VStack,
    Text,
    InputGroup,
    InputLeftElement,
    Input,
    Divider,
    HStack,
    Heading,
    Checkbox,
    useToast,
} from "@chakra-ui/react";
import { LockIcon, EmailIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import supabase from "../../config/supabaseClient";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [credentials, setCredentials] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    const handleChangeCredentials = (e: ChangeEvent<HTMLInputElement>) => {
        setCredentials({ ...credentials, [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value });
    };

    const handleLogin = async () => {
        setIsSubmitting(true);
        const { data, error } = await supabase.auth.signInWithPassword(credentials);

        if (error) {
            toast({
                title: "Innlogging feilet",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setIsSubmitting(false);
            return;
        }

        if (data) {
            navigate("/");
            return;
        }
        setIsSubmitting(false);
    };

    return (
        <HStack justifyContent={"center"} my={"60px"}>
            <Card w={"xl"} maxW={"100%"}>
                <CardHeader>
                    <Heading textAlign={"center"} fontWeight={"normal"}>
                        Medlemsinnlogging
                    </Heading>
                </CardHeader>
                <CardBody>
                    <VStack>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none" children={<EmailIcon color="gray" />} />
                            <Input
                                name="email"
                                placeholder="Epost"
                                value={credentials.email}
                                onChange={handleChangeCredentials}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        handleLogin();
                                    }
                                }}
                            ></Input>
                        </InputGroup>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none" children={<LockIcon color="gray" />} />
                            <Input
                                name="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Passord"
                                value={credentials.password}
                                onChange={handleChangeCredentials}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        handleLogin();
                                    }
                                }}
                            ></Input>
                        </InputGroup>
                        <Checkbox
                            isChecked={showPassword}
                            alignSelf="start"
                            onChange={(e) => setShowPassword(e.target.checked)}
                            paddingLeft={1}
                        >
                            Vis passord
                        </Checkbox>
                    </VStack>
                </CardBody>

                <CardFooter>
                    <VStack flex={1} spacing={4}>
                        <Button w={"100%"} colorScheme="blue" onClick={handleLogin} isDisabled={isSubmitting}>
                            Logg inn
                        </Button>
                        <Text>
                            Glemt passord? Trykk{" "}
                            <span
                                style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
                                onClick={() => navigate("/glemtpassord", { state: { email: credentials.email } })}
                            >
                                her
                            </span>
                        </Text>
                        <Divider />
                        <Text>
                            Lyst til å bli medlem? Les mer{" "}
                            <Link to="/medlemmer" style={{ textDecoration: "underline", color: "blue" }}>
                                her
                            </Link>
                        </Text>
                    </VStack>
                </CardFooter>
            </Card>
        </HStack>
    );
};

export default Login;
