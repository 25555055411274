import { useState, useContext } from "react";
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    HStack,
    Image,
    Menu,
    Text,
    MenuButton,
    MenuItem,
    MenuList,
    Show,
    useToast,
    VStack,
    Divider,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { HamburgerIcon, ChevronDownIcon, EditIcon, ChevronUpIcon, SettingsIcon } from "@chakra-ui/icons";
import { LogoutIcon } from "./CustomIcons";

import logo from "../assets/logo.png";
import { AuthContext } from "../App";
import supabase from "../config/supabaseClient";

interface Route {
    displayName: string;
    path?: string;
    authUsersOnly?: boolean;
    unAuthUsersOnly?: boolean;
    adminOnly?: boolean;
    children?: Route[];
}

const routes: Route[] = [
    { displayName: "Forside", path: "/", authUsersOnly: false },
    { displayName: "Nyheter", path: "/nyheter", authUsersOnly: false },
    {
        displayName: "Om NHK",
        authUsersOnly: false,
        children: [
            { displayName: "Om oss", path: "/om" },
            { displayName: "Styret", path: "/styret" },
            { displayName: "Vedtekter", path: "/vedtekter", authUsersOnly: true },
        ],
    },
    { displayName: "Seilaser", path: "/seilaser" },
    {
        displayName: "For medlemmer",
        authUsersOnly: true,
        children: [
            { displayName: "Medlemsliste", path: "/medlemmer" },
            { displayName: "Aktiviteter", path: "/aktiviteter" },
            { displayName: "Oppslagstavle", path: "/oppslagstavle" },
            { displayName: "Bøyer", path: "/boyer" },
            { displayName: "NHK Butikken", path: "/butikk" },
            { displayName: "GF-Protokoller", path: "/generforsamlinger" },
            { displayName: "Neptuns arkiv", path: "/neptuns" },
            { displayName: "Bildearkiv", path: "/bildearkiv" },
            { displayName: "Søknad om medlemskap", path: "/soeknad" },
        ],
    },
    {
        displayName: "For administratorer",
        adminOnly: true,
        children: [{ displayName: "Problemer", path: "/problemer" }],
    },
    { displayName: "Søknad om medlemskap", path: "/soeknad", authUsersOnly: false, unAuthUsersOnly: true },
];

const Navbar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { user, isAdmin } = useContext(AuthContext);
    const toast = useToast();
    const navigate = useNavigate();

    const handleSignOut = async () => {
        const { error } = await supabase.auth.signOut();

        if (error) {
            toast({
                title: "En feil oppsto",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const UserActionMenu = ({
        handleViewProfileClick,
        handleSignOutClick,
        isMobile = false,
    }: {
        handleViewProfileClick: () => void;
        handleSignOutClick: () => void;
        isMobile?: boolean;
    }) => {
        if (!user) {
            return null;
        }
        return (
            <Menu>
                <MenuButton
                    as={Button}
                    width={isMobile ? "100%" : undefined}
                    rightIcon={isMobile ? <ChevronUpIcon fontSize={"xl"} /> : <ChevronDownIcon fontSize={"xl"} />}
                    leftIcon={<SettingsIcon fontSize={"xl"} />}
                >
                    Min profil
                </MenuButton>
                <MenuList>
                    <Text sx={{ mx: 2 }} color="grey">
                        Innlogget som: {user.email}
                    </Text>
                    <Divider sx={{ my: 2 }} />
                    <MenuItem icon={<EditIcon />} onClick={handleViewProfileClick}>
                        Se / endre min profil
                    </MenuItem>
                    <MenuItem icon={<LogoutIcon />} onClick={handleSignOutClick}>
                        Logg ut
                    </MenuItem>
                </MenuList>
            </Menu>
        );
    };

    return (
        <nav style={{ padding: "8px 0px 8px 0px" }}>
            <HStack spacing={"24px"}>
                <Image height={"100px"} src={logo} alt="NHK's logo" />
                <Show above={"md"}>
                    {routes.map((route) =>
                        route.children ? (
                            <Box
                                key={route.path}
                                display={
                                    (route.authUsersOnly && !user) ||
                                    (route.unAuthUsersOnly && user) ||
                                    (route.adminOnly && !isAdmin)
                                        ? "none"
                                        : "block"
                                }
                            >
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        variant="ghost"
                                        fontWeight={"normal"}
                                        _hover={{ bg: "transparent" }}
                                        _focus={{ bg: "transparent" }}
                                        _expanded={{ bg: "transparent" }}
                                        px={0}
                                    >
                                        {route.displayName}
                                    </MenuButton>
                                    <MenuList>
                                        {route.children.map((child) => (
                                            <MenuItem
                                                key={child.path}
                                                onClick={() => navigate(child.path || "/")}
                                                display={child.authUsersOnly && !user ? "none" : "block"}
                                            >
                                                {child.displayName}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </Menu>
                            </Box>
                        ) : (
                            <Link
                                key={route.path}
                                to={route.path || "/"}
                                style={{
                                    display:
                                        (route.authUsersOnly && !user) ||
                                        (route.unAuthUsersOnly && user) ||
                                        (route.adminOnly && !isAdmin)
                                            ? "none"
                                            : "block",
                                }}
                            >
                                {route.displayName}
                            </Link>
                        )
                    )}
                    <Box flex={1} />
                    {user ? (
                        <UserActionMenu
                            handleViewProfileClick={() => navigate("/profil")}
                            handleSignOutClick={handleSignOut}
                        />
                    ) : (
                        <Link to="/innlogging">
                            <Button variant="unstyled">Logg inn</Button>
                        </Link>
                    )}
                </Show>
                <Show below="md">
                    <Box flex={1} />
                    <Button
                        size={"md"}
                        variant="outline"
                        rightIcon={<HamburgerIcon />}
                        aria-label={"Hamburger meny"}
                        onClick={() => setDrawerOpen(true)}
                    >
                        MENY
                    </Button>
                </Show>
            </HStack>
            <Drawer isOpen={drawerOpen} placement="right" onClose={() => setDrawerOpen(false)}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton size={"lg"} />
                    <DrawerHeader>NHK</DrawerHeader>

                    <DrawerBody>
                        <VStack alignItems={"start"}>
                            {routes.map((route) =>
                                route.children ? (
                                    <Box
                                        key={route.path}
                                        display={
                                            (route.authUsersOnly && !user) ||
                                            (route.unAuthUsersOnly && user) ||
                                            (route.adminOnly && !isAdmin)
                                                ? "none"
                                                : "block"
                                        }
                                    >
                                        <Menu>
                                            <MenuButton
                                                as={Button}
                                                rightIcon={<ChevronDownIcon />}
                                                variant="ghost"
                                                px={0}
                                                _hover={{ bg: "transparent" }}
                                                _focus={{ bg: "transparent" }}
                                                _expanded={{ bg: "transparent" }}
                                            >
                                                {route.displayName}
                                            </MenuButton>
                                            <MenuList>
                                                {route.children.map((child) => (
                                                    <MenuItem
                                                        key={child.path}
                                                        onClick={() => {
                                                            navigate(child.path || "/");
                                                            setDrawerOpen(false);
                                                        }}
                                                    >
                                                        {child.displayName}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </Menu>
                                    </Box>
                                ) : (
                                    <Link
                                        key={route.path}
                                        to={route.path || "/"}
                                        style={{
                                            display:
                                                (route.authUsersOnly && !user) ||
                                                (route.unAuthUsersOnly && user) ||
                                                (route.adminOnly && !isAdmin)
                                                    ? "none"
                                                    : "block",
                                        }}
                                    >
                                        <Button variant={"unstyled"} onClick={() => setDrawerOpen(false)}>
                                            {route.displayName}
                                        </Button>
                                    </Link>
                                )
                            )}
                        </VStack>
                    </DrawerBody>
                    <DrawerFooter>
                        {user ? (
                            <UserActionMenu
                                handleViewProfileClick={() => {
                                    setDrawerOpen(false);
                                    navigate("/profil");
                                }}
                                handleSignOutClick={handleSignOut}
                                isMobile={true}
                            />
                        ) : (
                            <Link to="innlogging" style={{ width: "100%" }}>
                                <Button
                                    w={"100%"}
                                    colorScheme="blue"
                                    onClick={() => {
                                        setDrawerOpen(false);
                                    }}
                                >
                                    Medlemsinnlogging
                                </Button>
                            </Link>
                        )}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </nav>
    );
};

export default Navbar;
